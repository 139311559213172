:root {
  --color--primary: #007AFF;
  --color--secondary: #F7F7F7;
  --color--secondary-active: #eeeeee;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.words-items {
  display: flex;
  flex-direction: column;
}
.word-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 2px;
  border-radius: 5px;
  color: inherit;
  background-color: var(--color--secondary);
  text-decoration: none;
  cursor: pointer;
}
.word-item.active {
  background-color: var(--color--secondary-active);
}
.word-item.active .word-item__word::after {
  /* content: url(images/volume_up_color.svg); */
  /* margin-top: 2px; */
  line-height: 5px;
  margin-left: 7px;
}
.word-item .word-item__word {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
}
.word-item .word-item__translate {
  opacity: 0.6;
}

.sections-items {
  display: flex;
  flex-direction: column;
}
.section-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 2px;
  border-radius: 5px;
  color: inherit;
  background-color: var(--color--secondary);
  text-decoration: none;
  cursor: pointer;
}

.box-with-cover-wrapper {
  display: flex;
  flex-direction: column;
}
.box-with-cover {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  min-height: 145px;
  padding: 30px 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  color: var(--color--secondary);
  background-color: var(--color--secondary);
  background-size: cover;
  background-position: center, center;
  background-repeat: no-repeat;

  text-decoration: none;
  cursor: pointer;

  transition: all 0.35s;
}
.box-with-cover.box-with-cover_type-1 {
  background-image: 
      /* top, transparent red */ 
      linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
      ),
      /* bottom, image */
      url(images/bg-type-1.jpg);
}
.box-with-cover .box-with-cover__title {
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.box-with-cover:hover {
  transform: scale(1.015);
}